<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        Summary Collection Report
                        <search-button style="margin-left: 25px" permission="billing-report-collection"
                                        @action="get()">
                            View Report
                        </search-button>
                        <v-spacer></v-spacer>
                        <print-buttons v-if="collection.length" :downloadUrl="downloadUrl"
                                        :passAuthToReport="true" style="float: right"
                                        :excel="true" :pdf="true" labelExcel="Download Excel" labelPdf="Download Pdf"></print-buttons>
                    </v-card-title>
                    <v-card outlined>
                        <v-card-title class="title pb-0s">
                        </v-card-title>
                    </v-card>

                    <v-data-table :headers="headers"
                                  :items="collection"
                                  hide-default-footer
                                  :loading="form.loading">
                        <template  v-slot:item="{index, item}">
                            <tr>
                                <td class="text-xs-center">{{index + 1}}</td>
                                <td class="text-xs-center">{{getMonthNameFromString(item.bill_month)}}</td>
                                <td class="text-xs-center">{{item.billing_amount.numberFormat()}}</td>
                                <td class="text-xs-center">{{item.previous_due_collected_amount.numberFormat()}}
                                </td>
                                <td class="text-xs-center">{{item.bill_collected_amount.numberFormat()}}</td>
                                <td class="text-xs-center">{{item.advance_collected.numberFormat()}}</td>
                                <td class="text-xs-center">{{item.extra_collected.numberFormat()}}</td>
                                <td class="text-xs-center">{{(item.previous_due_collected_amount +
                                    item.advance_collected + item.bill_collected_amount +
                                    item.extra_collected).numberFormat()}}
                                </td>
                                <td class="text-xs-center">{{(item.previous_due_amount -
                                    item.previous_due_collected_amount).numberFormat()}}
                                </td>
                                <td class="text-xs-center">{{item.bill_due_amount.numberFormat()}}</td>
                                <td class="text-xs-center">{{(item.bill_due_amount +
                                    item.previous_due_amount -
                                    item.previous_due_collected_amount).numberFormat()}}
                                </td>
                                <td class="text-xs-center">{{item.instant_discount.numberFormat()}}</td>
                            </tr>
                        </template>
                        <template  v-slot:body.append v-if="collection.length > 0">
                            <tr>
                                <th class="text-xs-center">Total</th>
                                <th class="text-xs-center">-</th>
                                <th class="text-xs-center">{{allSum.billing_amount.numberFormat()}}</th>
                                <th class="text-xs-center">{{allSum.previous_due_collected_amount.numberFormat()}}</th>
                                <th class="text-xs-center">{{allSum.bill_collected_amount.numberFormat()}}</th>
                                <th class="text-xs-center">{{allSum.advance_collected.numberFormat()}}</th>
                                <th class="text-xs-center">{{allSum.extra_collected.numberFormat()}}</th>
                                <th class="text-xs-center">{{allSum.total_collected_amount.numberFormat()}}</th>
                                <th class="text-xs-center">{{allSum.previous_due_remaining.numberFormat()}}</th>
                                <th class="text-xs-center">{{allSum.bill_due_amount.numberFormat()}}</th>
                                <th class="text-xs-center">{{allSum.total_billing_due.numberFormat()}}</th>
                                <th class="text-xs-center">{{allSum.instant_discount.numberFormat()}}</th>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import {getMonthNameFromString} from '@/library/helpers'

    export default {
        data: () => ({
            gradeLoading: false,
            form: new Form({
                amount: '',
                enroll_code: '',
                stat: 0,

            }, '/api/report/summary-report'),
            pagination: {
                rowsPerPage: 25,
            },
            collection: [],
            allSum: {},
            downloadUrl: '',

            headers: [
                {text: 'S.N', align: 'left', value: 'id', sortable: false},
                {text: 'Month', align: 'left', value: 'month', sortable: false},
                {text: 'Billing Amount', align: 'left', value: 'billing_amount', sortable: false},
                {text: 'Previsous Due Collected Amount', align: 'left', value: 'grade', sortable: false},
                {text: 'Bill Collected ', align: 'left', value: 'amount', sortable: false},
                {text: 'Advance Collected', align: 'left', value: 'amount', sortable: false},
                {text: 'Extra Amount Collected', align: 'left', value: 'amount', sortable: false},
                {text: 'Total Collected Amount', align: 'left', value: 'amount', sortable: false},
                {text: 'Previous Due Remaining Amount', align: 'left', value: 'id', sortable: false},
                {text: 'Billing Due Amount', align: 'left', value: 'amount', sortable: false},
                {text: 'Total Due Amount', align: 'left', value: 'amount', sortable: false},
                {text: 'Instant Discount', align: 'left', value: 'amount', sortable: false},
            ],
        }),

        computed: {
            ...mapState(['batch']),
        },

        mounted() {
            // this.get();
        },

        watch: {
            'pagination': function () {
                // this.get();
            },
            'batch': function (value) {
                // this.get();
            },
        },

        methods: {
            getMonthNameFromString,
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&');
            },

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.collection = data.data;
                    this.allSum = data.all_sum;
                    this.downloadUrl = data.download_url;
                })

            },
        }
    }
</script>
<style lang="scss">
</style>